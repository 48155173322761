import React from "react";
import './home.scss';
import { DishForm } from "../../components/dish-form/DishForm";
import { Table } from "../../components/table/Table";

const Home = () => {
    
    return(<>
        <div className="bhome__container">
            <div className="bhome__manager-container">
                <DishForm />
                <Table />
            </div>
            <div className="bhome__menu-sample">
        
            </div>
        </div>
    </>);
}

export { Home }; 